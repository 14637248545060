import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AlertsService {

  bunTexts = [
              ["סבבה"],["אוקיוש"],["אחלה"],["אחלוש"],["אוקיי"],["סבבוש"],["סבבי"],
              ["טוביקו"],["יולו"],["פסדר"]
          ]

  constructor() { }

  alreadyInDatabase(){
    let alert = document.createElement("ion-alert");
    alert.buttons=this.bunTexts[this.random(this.bunTexts)];
    alert.header = "השם כבר קיים";
    alert.message = "השם שהוצע כבר קיים במאגר";
    alert.cssClass ="alert-custom";
    document.body.appendChild(alert);
    alert.present();
  }

  sentSuccessfully(){
    let alert = document.createElement("ion-alert");
    alert.buttons=this.bunTexts[this.random(this.bunTexts)];
    alert.header = "ההצעה נשלחה";
    alert.message = "תודה!";
    alert.cssClass ="alert-custom";
    document.body.appendChild(alert);
    alert.present();
  }

  failiure(){
    let alert = document.createElement("ion-alert");
    alert.buttons=this.bunTexts[this.random(this.bunTexts)];
    alert.header = "אופסי!";
    alert.message = "חלה שגיאה. יש לכם אינטרנט?";
    alert.cssClass ="alert-custom";
    document.body.appendChild(alert);
    alert.present();
  }

  random(array){
    return Math.floor(Math.random()*array.length);
  }
}
