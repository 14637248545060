import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, tap } from 'rxjs/operators';
import { AlertsService } from './alerts.service';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  backupDB = {
    firstNameBoys: [ "ג'וש", "ג'ון", "ווייד", "ג'ייק", "מייק", "סאם", "ביל", "דייויד", "פול", "ג'ורג'", "לואיס", "דניס", "הנרי", "יוהאן", "טימותי", "פרדי", "ג'ו", "זאק", "הארי", "ג'ייקוב", "ג'ק", "אוסקר", "צ'ארלי", "לוק", "ריאן", "תומאס", "טיילר", "וויליאם", "לוגאן", "מקס", "לוקאס", "אדוארד", "אד", "דילן", "קודי", "סבסטיאן", "טובי", "מאט", "מייקל", "סטנלי", "קונור", "פרדריק", "אלברט", "ג'יימס", "ג'יימי", "פליקס", "רוברט", "רודריגו", "שון", "דומיניק", "אנתוני", "כריס", "כריסטופר", "אנדרו", "רובין", "אוסטין", "ברנדון", "אלן", "קולין", "בראד", "רופרט", "יוג'ין", "הרולד", "אלפרד", "ג'רמי", "ג'רלד", "בראד", "ניל", "האנס", "מיטצ'ל", "ארנסט", "מרטין", "גראנט", "קליפורד", "גולדי", "קורי", "וינסנט" ],
    firstNameGirls: [ "מרתה", "ג'ניפר", "מייגן", "אגאתה", "קייט", "רובין", "ברנדה", "גוון", "סמנת'ה", "מינדי", "רמונה", "ג'יין", "אנני", "ג'וליה", "ננסי", "מילי", "דייזי", "פייג'", "אמילי", "סקרלט", "ריילי", "אליסון", "קלרה", "לונה", "קלואי", "סשה", "פיבי", "רייצ'ל", "קים", "הייזל", "ויולט", "קלייר", "סקיילר", "שארלוט", "ליסה", "מישל", "דבי", "סלינה", "רג'ינה" ],
    lastName: [ "מויאל", "בוסקילה", "פטוקה", "חתוכה", "כהן", "לוי", "ביטון", "מעודה", "חודדה", "מסיקה", "ברדוגו", "אבוטבול", "אמזלג", "סוויסה", "בן חמו", "אפללו", "ישראלי", "צרפתי", "אזולאי", "בן לולו", "אילוז", "ממן", "פחימה", "כנאפו", "סרוסי", "פרץ", "מלכה", "עמר", "חזן", "חג'בי", "טביב", "מחפוד", "קאפח", "דנגור", "טסה", "אבוקסיס", "אסולין", "איבגי", "אוחנה", "אלפאסי", "אמסלם", "בוזגלו", "הרוש", "יפרח", "מרציאנו", "פדלון", "חלאבי", "שושן", "עטיה", "עטר", "סמוכה", "עזיזה", "גוזלן", "חג'ג'", "מעתוק", "תורג'מן", "אוזן", "רביבו", "עבודי", "רג'ואן", "בכר", "פלח", "שאשא", "גואטה", "גונדר", "בוכניק", "מלול", "אסייג", "שטרית", "מועלם", "אבוקרט", "אריכא", "עוזרי" ]
  };

  database = "https://namedissonancegen.firebaseio.com/data";
  suggestionsURL = "https://namedissonancegen.firebaseio.com/suggestions/";
  firstNameBoys = {names: [], used: []};
  firstNameGirls = {names: [], used: []};
  lastNames = {names: [], used: []};
  clicks = 0;
  localClicks = 0;

  suggestions = {
    boys: [],
    girls: [],
    families: []
  };

  constructor(private http: HttpClient,
              private alerts: AlertsService) {}

  getNames(){
    return this.http.get<
                        { firstNameBoys: [],
                          firstNameGirls: [],
                          lastName: [],
                          clicks}
                        >(this.database+".json")
                        .pipe(
                          tap( data =>{
                                        this.firstNameBoys.names = data.firstNameBoys;
                                        this.firstNameGirls.names = data.firstNameGirls;
                                        this.lastNames.names = data.lastName;
                                        this.clicks = data.clicks;
                                      },
                                error => {
                                  this.clicks = +localStorage.getItem("offlineClicks");
                                  this.firstNameBoys.names = this.backupDB.firstNameBoys;
                                  this.firstNameGirls.names = this.backupDB.firstNameGirls;
                                  this.lastNames.names = this.backupDB.lastName;;
                                }
                              )
                        ).subscribe( data => {
                          // if(localStorage.getItem("offlineClicks")!=null){
                          //   console.log("Loaded local data");
                          //   this.updateClicks(this.clicks+localStorage.getItem("offlineClicks"));
                          //   localStorage.removeItem("offlineClicks");
                          // }
                        });
  }

  getData(){
    return this.http.get<
                        { firstNameBoys: [],
                          firstNameGirls: [],
                          lastName: [],
                          clicks}
                        >(this.database+".json")
  }

  getSuggestions(){
    return this.http.get<{
                          boys: {name: String},
                          girls: {name: String},
                          family: {name: String},
                        }>(this.suggestionsURL+".json")
  }

  updateClicks(number){
    this.http.put(this.database+"/clicks.json", number).subscribe( data => {}, error => {
      console.log("hop");
      localStorage.setItem("offlineClicks", (+localStorage.getItem("offlineClicks")+1).toString());
    });
  }

  getClicks(){
    return this.http.get<{clicks}>(this.database+"/clicks.json")
                        .pipe(
                          tap( data =>{
                                        this.clicks = data.clicks;
                                      },
                                error => {}
                              )
                        ).subscribe();
    }

  sendSuggestion(type: String, name: String){
    let path;
    switch(type){
      case "boys":{
        path = "boys.json"
        break;
      }
      case "girls":{
        path = "girls.json"
        break;
      }
      case "family":{
        path = "family.json"
        break;
      }
    }
    this.http.post(this.suggestionsURL+path, {name}).subscribe(data => {
      this.alerts.sentSuccessfully();
    }, error => {
      this.alerts.failiure();
    });
  }
  deleteSuggestion(id, name, type){
    this.http.delete(this.suggestionsURL+type+"/"+id+".json").subscribe(
      data => {
        switch(type){
          case "boys":{
            for(let i=0; i<this.suggestions.boys.length;i++){
              if(this.suggestions.boys[i].id==id){
                this.suggestions.boys.splice(i, 1);
              }
            }
            break;
          }
          case "girls":{
            for(let i=0; i<this.suggestions.girls.length;i++){
              if(this.suggestions.girls[i].id==id){
                this.suggestions.girls.splice(i, 1);
              }
            }
            break;
          }
          case "family":{
            for(let i=0; i<this.suggestions.families.length;i++){
              if(this.suggestions.families[i].id==id){
                this.suggestions.families.splice(i, 1);
              }
            }
            break;
          }
        }
      }
    );
  }

  confirmSuggestion(id, name, type){
    let newNames;
    let path;
    switch(type){
      case "boys":{
        newNames = this.firstNameBoys.names.slice();
        path="firstNameBoys";
        break;
      }
      case "girls":{
        newNames = this.firstNameGirls.names.slice();
        path="firstNameGirls";
        break;
      }
      case "family":{
        newNames = this.lastNames.names.slice();
        path="lastName";
        break;
      }
    }
    newNames.push(name);
    this.http.put(this.database+"/"+path+".json", newNames).subscribe(data => {
      this.deleteSuggestion(id, name, type);
      this.getNames();
    })
  }
}
